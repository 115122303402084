<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row>
      <v-col cols="3">
        <create-giving-video-card />
      </v-col>
      <v-col>
        <default-giving-video-card></default-giving-video-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const CreateGivingVideoCard = () => import("../cards/CreateGivingVideoCard.vue");
const DefaultGivingVideoCard = () => import("../cards/DefaultGivingVideoCard.vue");

export default {
  components: {
    CreateGivingVideoCard,
    DefaultGivingVideoCard
  }
}
</script>
